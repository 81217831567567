// How hero

.how-hero {
  display: flex;
  justify-content: center;
  padding: 146px 0;
  background-color: $blue-light-1;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 114px 0 35px 0;
  }
  .bg {
    bottom: 60px;
    @media only screen and (max-width: $r-768) {
      bottom: 100px;
      right: 0;
    }
    svg {
      max-width: 100%;
    }
  }
  .text {
    text-align: center;
    margin-bottom: 73px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 35px;
    }
    .heading-h1 {
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 25px;
      }
    }
    .buttons {
      text-align: center;
      .button-yellow {
        width: 100%;
        min-width: initial;
        max-width: 260px;
        margin: 0 12px;
        @media only screen and (max-width: $r-768) {
          margin: 0 0 20px 0;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .wrapper {
    .show-desctop {
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
    .show-mobile {
      display: none;
      @media only screen and (max-width: $r-768) {
        display: block;
      }
    }
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-768) {
          display: flex;
          justify-content: flex-start;
        }
        .image {
          .vector {
            left: -20px;
            bottom: 0;
            @media only screen and (max-width: $r-768) {
              left: -10px;
            }
          }
        }
      }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-768) {
          display: flex;
          justify-content: center;
          margin-top: -40px;
        }
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          .vector {
            width: 453px;
            @media only screen and (max-width: $r-768) {
              width: 140px;
              left: -45px;
              bottom: -45px;
            }
            img {
              &:first-of-type {
                @media only screen and (max-width: $r-768) {
                  display: none;
                }
              }
              &:nth-of-type(2) {
                display: none;
                @media only screen and (max-width: $r-768) {
                  display: block;
                }
              }
            }
          }
        }
      }
      &:last-of-type {
        @media only screen and (max-width: $r-768) {
          display: flex;
          justify-content: flex-end;
          margin-top: -45px;
        }
        .image {
          .vector {
            right: -20px;
            bottom: 0;
            @media only screen and (max-width: $r-768) {
              right: -10px;
            }
          }
        }
      }
    }
    .image {
      position: relative;
      z-index: 1;
      max-width: 300px;
      margin: 0 auto;
      text-align: center;
      @media only screen and (max-width: $r-768) {
        max-width: 120px;
        margin: 0;
      }
      img {
        height: 300px;
        @media only screen and (max-width: $r-768) {
          height: 120px;
        }
      }
      .vector {
        position: absolute;
        z-index: -1;
        @media only screen and (max-width: $r-768) {
          width: 100%;
          height: 100%;
        }
        img {
          height: auto;
          &:nth-of-type(2) {
            display: none;
          }
        }
      }
    }
  }
}

// How customers

.how-customers {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 120px 0 50px 0;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0 25px 0;
  }
  .bg {
    top: 80px;
    @media only screen and (max-width: $r-768) {
      top: 25px;
      right: 16px;
    }
    svg {
      max-width: 100%;
    }
  }
  .row {
    align-items: flex-end;
  }
  .image {
    @media only screen and (max-width: $r-992) {
      text-align: center;
      img {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }
  .text {
    width: 100%;
    max-width: 593px;
    @media only screen and (max-width: $r-992) {
      max-width: 100%;
      margin-bottom: 35px;
    }
    .additional-l {
      margin-bottom: 19px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
    }
    .heading-h1 {
      margin-bottom: 26px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 22px;
      }
    }
    .paragraph-l {
      margin-bottom: 19px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      p {
        font-weight: 700;
      }
    }
    .list {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #D6EAF2;
        border-radius: 24px;
        @media only screen and (max-width: $r-768) {
          height: 2px;
        }
      }
      ul {
        li {
          padding: 23px 15px 23px 56px;
          @media only screen and (max-width: $r-768) {
            padding: 16px 15px 16px 45px;
          }
          position: relative;
          &:before {
            position: absolute;
            content: '';
            left: 13px;
            top: 32px;
            width: 12px;
            height: 12px;
            background-color: $yellow;
            border-radius: 50%;
            @media only screen and (max-width: $r-768) {
              top: 22px;
            }
          }
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #D6EAF2;
            border-radius: 24px;
            @media only screen and (max-width: $r-768) {
              height: 2px;
            }
          }
        }
        ul {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            padding: 13px 0 13px 32px;
            width: 33%;
            margin-bottom: 0;
            font-size: 16px;
            @media only screen and (max-width: $r-768) {
              width: 50%;
              font-size: 14px;
            }
            &:before {
              background-color: transparent;
              background-image: url("../img/icon-how-customers.svg");
              background-size: contain;
              background-repeat: no-repeat;
              width: 23px;
              height: 17px;
              top: 15px;
              left: 0;
            }
            &:after {
              display: none;
            }
            &:last-of-type {
              background-image: url("../img/vector-how-list.svg");
              background-size: contain;
              background-repeat: no-repeat;
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// How steps

.how-steps {
  display: flex;
  justify-content: center;
  padding: 50px 0 120px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 25px 0 40px 0;
  }
  .bg {
    top: 40px;
    @media only screen and (max-width: $r-768) {
      left: 0;
      top: 0;
    }
    svg {
      max-width: 100%;
    }
  }
  .text {
    margin-bottom: 60px;
    text-align: center;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 40px;
    }
  }
  .wrapper {
    margin-bottom: 60px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 40px;
    }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      &:first-of-type {
        .item {
          .vector {
            top: 19px;
            right: 0;
            @media only screen and (max-width: $r-768) {
              top: 11px;
              right: 19px;
              height: calc(100% - 19px);
              img {
                height: 100%;
              }
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $blue;
              }
            }
          }
        }
      }
      &:nth-of-type(2) {
        .item {
          .vector {
            width: 100%;
            top: 85px;
            left: 0;
            @media only screen and (max-width: $r-768) {
              top: 0;
              left: initial;
              right: 21px;
              height: 100%;
              width: initial;
              img {
                height: 100%;
              }
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $marine;
              }
            }
          }
        }
      }
      &:nth-of-type(3) {
        .item {
          .vector {
            width: 100%;
            top: 16px;
            left: 0;
            @media only screen and (max-width: $r-768) {
              top: 4px;
              left: 35px;
              img {
                height: 100%;
              }
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $yellow;
              }
            }
          }
        }
      }
    }
    .item {
      height: 100%;
      padding: 70px 40px 40px 40px;
      background-color: $blue-light-1;
      border: 4px solid $blue-light-2;
      border-radius: 10px;
      position: relative;
      @media only screen and (max-width: $r-992) {
        padding: 70px 15px 40px 15px;
        max-width: 328px;
        margin: 0 auto;
      }
      @media only screen and (max-width: $r-768) {
        padding: 30px 60px 10px 30px;
        border-width: 2px;
      }
      .icon {
        img {
          &:first-of-type {
            @media only screen and (max-width: $r-768) {
              display: none;
            }
          }
          &:nth-of-type(2) {
            display: none;
            @media only screen and (max-width: $r-768) {
              display: block;
            }
          }
        }
      }
      .vector {
        position: absolute;
        img {
          &:first-of-type {
            @media only screen and (max-width: $r-768) {
              display: none;
            }
          }
          &:nth-of-type(2) {
            display: none;
            @media only screen and (max-width: $r-768) {
              display: block;
            }
          }
        }
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 57px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 26px;
        }
        .icon {
          margin-right: 15px;
          img {
            height: 80px;
            @media only screen and (max-width: $r-768) {
              height: 70px;
            }
          }
        }
        .paragraph-extraBold-l {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .buttons {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: $r-768) {
      flex-direction: column;
    }
    .button-yellow {
      min-width: 230px;
      height: 60px;
      margin: 0 12px;
      @media only screen and (max-width: $r-768) {
        width: 100%;
        height: 50px;
        margin: 0 0 20px 0;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .flex {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 20px;
          svg {
            height: 30px;
          }
        }
        .additional-s {
          p {
            margin-bottom: 3px;
            font-weight: 500;
            font-size: 12px;
            line-height: 127.4%;
            text-transform: initial;
            text-align: left;
          }
        }
        .paragraph-semiBold {
          p {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 127.4%;
            letter-spacing: 0.095em;
            text-align: left;
            @media only screen and (max-width: $r-768) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

// How providers

.how-providers {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: $blue-light-1;
  padding: 120px 0 50px 0;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0 25px 0;
  }
  .bg {
    top: 40px;
    @media only screen and (max-width: $r-768) {
      right: 10px;
      top: 10px;
    }
    svg {
      max-width: 100%;
    }
  }
  .row {
    align-items: flex-end;
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-992) {
          order: 2;
        }
      }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-992) {
          order: 1;
        }
      }
    }
  }
  .image {
    @media only screen and (max-width: $r-992) {
      text-align: center;
      img {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }
  .text {
    width: 100%;
    max-width: 593px;
    @media only screen and (max-width: $r-992) {
      max-width: 100%;
      margin-bottom: 35px;
    }
    .additional-l {
      margin-bottom: 19px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
    }
    .heading-h1 {
      margin-bottom: 26px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 22px;
      }
    }
    .paragraph-l {
      margin-bottom: 19px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      p {
        font-weight: 700;
      }
    }
    .list {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #D6EAF2;
        border-radius: 24px;
        @media only screen and (max-width: $r-768) {
          height: 2px;
        }
      }
      ul {
        li {
          padding: 23px 15px 23px 56px;
          position: relative;
          @media only screen and (max-width: $r-768) {
            padding: 16px 15px 16px 45px;
          }
          &:before {
            position: absolute;
            content: '';
            left: 13px;
            top: 32px;
            width: 12px;
            height: 12px;
            background-color: $yellow;
            border-radius: 50%;
            @media only screen and (max-width: $r-768) {
              top: 22px;
            }
          }
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #D6EAF2;
            border-radius: 24px;
            @media only screen and (max-width: $r-768) {
              height: 2px;
            }
          }
        }
        ul {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            padding: 0 0 0 32px;
            width: 33%;
            margin-bottom: 12px;
            font-size: 16px;
            @media only screen and (max-width: $r-768) {
              width: 50%;
              font-size: 14px;
            }
            &:before {
              background-color: transparent;
              background-image: url("../img/icon-how-customers.svg");
              background-size: contain;
              background-repeat: no-repeat;
              width: 23px;
              height: 17px;
              top: 5px;
              left: 0;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

// How steps providers

.how-steps-providers {
  background-color: $blue-light-1;
  position: relative;
  .bg {
    top: -50px;
    @media only screen and (max-width: $r-768) {
      top: -20px;
      left: initial;
      right: 0;
    }
    svg {
      max-width: 100%;
    }
  }
  .wrapper {
    div[class*="col-"] {
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768) {
        max-width: 100%;
        width: 100%;
        flex: auto;
      }
      &:first-of-type {
        .item {
          .vector {
            top: 14px;
            right: 0;
            height: auto;
            @media only screen and (max-width: $r-768) {
              top: initial;
              bottom: 0;
              right: 40px;
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $blue;
              }
            }
          }
        }
      }
      &:nth-of-type(2) {
        .item {
          .vector {
            width: 100%;
            top: 52px;
            left: 0;
            @media only screen and (max-width: $r-768) {
              width: initial;
              height: 100%;
              left: 60px;
              top: 0;
            }
            svg {
              width: 100%;
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $marine;
              }
            }
          }
        }
      }
      &:nth-of-type(3) {
        .item {
          .vector {
            width: initial;
            top: 24px;
            right: 0;
            left: initial;
            @media only screen and (max-width: $r-768) {
              top: 0;
              right: 23px;
              height: 100%;
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $yellow;
              }
            }
          }
        }
      }
      &:nth-of-type(4) {
        .item {
          .vector {
            width: 100%;
            top: 72px;
            left: 0;
            @media only screen and (max-width: $r-768) {
              width: auto;
              left: 50px;
              top: 0;
            }
          }
          .flex {
            .paragraph-extraBold-l {
              p {
                color: $blue;
              }
            }
          }
        }
      }
    }
    .item {
      background-color: white;
      .paragraph-extraBold-l {
        p {
          &:first-of-type {
            @media only screen and (max-width: $r-768) {
              display: none;
            }
          }
          &:nth-of-type(2) {
            display: none;
            @media only screen and (max-width: $r-768) {
              display: block;
            }
          }
        }
      }
      .desc {
        p {
          &:first-of-type {
            @media only screen and (max-width: $r-768) {
              display: none;
            }
          }
          &:nth-of-type(2) {
            display: none;
            @media only screen and (max-width: $r-768) {
              display: block;
            }
          }
        }
      }
    }
  }
}