// Header

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: $blue-light-1;
  transition: .3s;
  z-index: 10;
  @media only screen and (max-width: $r-768) {
    padding: 12px 0;
  }
  &.sticky {
    transition: .3s;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }
  &.active {
    .logo {
      img {
        &:first-of-type {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
    }
  }
  .row {
    align-items: center;
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        position: initial;
      }
    }
  }
  .logo {
    img {
      height: 63px;
      @media only screen and (max-width: $r-768) {
        position: relative;
        z-index: 1;
        height: 48px;
      }
      &:nth-of-type(2) {
        display: none;
      }
    }
  }
  .hamburger {
    display: none;
    @media only screen and (max-width: $r-992) {
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 1;
    }
    &.active {
      svg {
        &:first-of-type {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
    }
    svg {
      &:nth-of-type(2) {
        display: none;
      }
    }
  }
  .nav-mobile {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 71px 15px 46px 15px;
    background-color: $blue-light-1;
    &.active {
      display: flex;
    }
    .bord {
      position: relative;
      padding-bottom: 45px;
      margin-bottom: 40px;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: rgba(84, 92, 133, 0.2);
      }
    }
    .additional-s {
      margin-bottom: 21px;
      font-weight: 600;
      font-size: 11px;
      line-height: 127.4%;
      text-transform: uppercase;
      color: rgba(31, 37, 69, 0.5);
    }
    .navigation {
      width: 100%;
      @media only screen and (max-width: $r-768) {
        flex-direction: column;
        padding-bottom: 15px;
      }
      ul {
        flex-direction: column;
        align-items: center;
        li {
          margin-bottom: 30px;

        }
      }
    }
    .downloads {
      width: 100%;
      text-align: center;
      ul {
        justify-content: center;
        li {
          margin: 0 19px;
        }
      }
    }
    .socials {
      width: 100%;
      text-align: center;
      ul {
        justify-content: space-between;
        li {
          &:before {
            display: none;
          }
        }
      }
    }
    .locations {
      width: 100%;
      max-width: 205px;
      @media only screen and (max-width: $r-768) {
        max-width: 100%;
      }
      .item {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        @media only screen and (max-width: $r-768) {
          justify-content: center;
        }
        .icon {
          width: 24px;
          min-width: 24px;
          margin-right: 10px;
          @media only screen and (max-width: $r-768) {
            opacity: 0.5;
          }
        }
        .additional-s {
          margin: 0;
          position: relative;
          top: 1px;
          @media only screen and (max-width: $r-768) {
            width: auto;
          }
          p, a {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 14px;
            text-transform: none;
            color: rgba(31, 37, 69, 0.5);
          }
          a {
            word-break: break-all;
            color: rgba(31, 37, 69, 0.5);
            &:hover {
              color: rgba(31, 37, 69, 0.5);
              &:before {
                left: 0;
                width: 100%;
              }
            }
            &:before {
              left: 50%;
              width: 0;
            }
          }
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: $r-992) {
      display: none;
    }
  }
}