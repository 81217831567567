.page-id-87 {
  .home-download {
    background-color: $blue-light-1;
  }
  .home-testimonials {
    background-color: white;
  }
}

// About hero

.about-hero {
  padding: 120px 0 80px 0;
  background-color: $blue-light-1;
  position: relative;
  .bg {
    left: 190px;
    bottom: 178px;
    @media only screen and (max-width: $r-768) {
      left: initial;
      right: 0;
      bottom: 32px;
    }
  }
  .row {
    align-items: center;
  }
  .text {
    width: 100%;
    max-width: 518px;
    position: relative;
    @media only screen and (max-width: $r-992) {
      text-align: center;
      margin: 0 auto 90px auto;
    }
    .vector {
      position: absolute;
      left: -30px;
      top: -18px;
      @media only screen and (max-width: $r-768) {
        left: -10px;
        top: -15px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .image {
    text-align: center;
    img {
      width: 100%;
      max-width: 526px;
    }
  }
}

// Text left

.text-left {
  padding: 120px 0;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0 85px 0;
  }
  .row {
    align-items: center;
  }
  .text {
    @media only screen and (max-width: $r-992) {
      margin-bottom: 80px;
    }
    .heading-h1 {
      margin-bottom: 28px;
      @media only screen and (max-width: $r-992) {
        text-align: center;
      }
      @media only screen and (max-width: $r-768) {
        margin-bottom: 24px;
      }
    }
    .paragraph-l {
      width: 100%;
      max-width: 530px;
      @media only screen and (max-width: $r-992) {
        max-width: 100%;
      }
    }
  }
  .image {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      width: 100%;
      max-width: 482px;
      margin: 0 auto;
    }
    img {
      width: 100%;
      max-width: 482px;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto;
      }
    }
    .vector {
      position: absolute;
      z-index: -1;
      right: 20px;
      bottom: -20px;
      @media only screen and (max-width: $r-768) {
        width: 100%;
        height: 100%;
        right: 10px;
        bottom: -15px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bg {
      right: 0;
      top: -150px;
    }
  }
}

// Text right

.text-right {
  padding: 120px 0;
  background-color: $blue-light-1;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0 85px 0;
  }
  .row {
    align-items: center;
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-992) {
          order: 2;
        }
      }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-992) {
          order: 1;
        }
      }
    }
  }
  .text {
    text-align: left;
    width: 100%;
    max-width: 514px;
    @media only screen and (max-width: $r-992) {
      margin-bottom: 80px;
      max-width: 100%;
    }
    .heading-h1 {
      margin-bottom: 28px;
      @media only screen and (max-width: $r-992) {
        text-align: center;
        max-width: 514px;
        margin: 0 auto 28px auto;
      }
      @media only screen and (max-width: $r-768) {
        margin-bottom: 24px;
      }
    }
    .paragraph-l {
      width: 100%;
      max-width: 530px;
      @media only screen and (max-width: $r-992) {
        max-width: 100%;
      }
    }
  }
  .image {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      width: 100%;
      max-width: 482px;
      margin: 0 auto;
    }
    img {
      width: 100%;
      max-width: 482px;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto;
      }
    }
    .vector {
      position: absolute;
      z-index: -1;
      left: 20px;
      top: -20px;
      @media only screen and (max-width: $r-768) {
        width: 100%;
        height: 100%;
        left: 10px;
        top: -15px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bg {
      left: 50px;
      top: -200px;
      @media only screen and (max-width: $r-768) {
        left: initial;
        right: 30px;
        top: -150px;
      }
    }
  }
}

// About connect

.about-connect {
  display: flex;
  justify-content: center;
  padding: 120px 0 40px 0;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
  }
  .animation {
    max-width: 485px;
    position: absolute;
    bottom: 0;
    z-index: -1;
    @media only screen and (max-width: $r-992) {
      bottom: 370px;
      max-width: 330px;
    }
    @media only screen and (max-width: $r-992) {
      bottom: 300px;
    }
    img {
      &.show-desctop {
        @media only screen and (max-width: $r-992) {
          display: none;
        }
      }
      &.show-mobile {
        display: none;
        @media only screen and (max-width: $r-992) {
          display: block;
        }
      }
    }
  }
  .text {
    text-align: center;
    margin-bottom: 130px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 31px;
    }
    .heading-h1 {
      width: 100%;
      max-width: 940px;
      margin: 0 auto 36px auto;
    }
    .paragraph-l {
      width: 100%;
      max-width: 892px;
      margin: 0 auto 27px auto;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 24px;
      }
    }
    .paragraph-extraBold-l {
      margin-bottom: 34px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 40px;
      }
    }
  }
  .wrapper {
    justify-content: space-between;
    .col-lg-5 {
      &:first-of-type {
        display: flex;
        justify-content: flex-start;
        @media only screen and (max-width: $r-992) {
          margin-bottom: 305px;
        }
      }
      &:last-of-type {
        display: flex;
        justify-content: flex-end;
      }
    }
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 405px;
      height: 405px;
      padding: 35px;
      background-color: $blue-light-1;
      border: 2px solid $blue-light-2;
      border-radius: 50%;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto;
      }
      @media only screen and (max-width: $r-768) {
        padding: 20px 35px;
        max-width: 336px;
        height: 336px;
      }
      .icon {
        margin-bottom: 22px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 20px;
          img {
            height: 64px;
          }
        }
      }
      .heading-h3 {
        margin-bottom: 18px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 16px;
          font-size: 20px;
        }
      }
      .paragraph-s {
        width: 100%;
        max-width: 300px;
        p {
          @media only screen and (max-width: $r-768) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

// About review

.about-review {
  padding: 120px 0;
  background-color: $blue-light-1;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0 60px 0;
  }
  .text {
    width: 100%;
    max-width: 504px;
    @media only screen and (max-width: $r-992) {
      text-align: center;
      margin: 0 auto 30px auto;
    }
    .icon {
      margin-bottom: 53px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 34px;
        svg {
          height: 44px;
        }
      }
    }
    .heading-h1 {
      margin-bottom: 28px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 30px;
      }
    }
    .paragraph-l {
      margin-bottom: 33px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 30px;
      }
    }
    .paragraph-s {
      margin-bottom: 5px;
      p {
        margin-bottom: 0;
        @media only screen and (max-width: $r-768) {
          font-size: 16px;
        }
      }
    }
    .additional-s {
      p {
        font-size: 14px;
        @media only screen and (max-width: $r-768) {
          font-size: 14px;
        }
      }
    }
  }
  .image {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      width: 100%;
      max-width: 459px;
      margin: 0 auto;
    }
    img {
      width: 100%;
      max-width: 459px;
      @media only screen and (max-width: $r-992) {
        max-width: 100%;
      }
    }
    .vector {
      position: absolute;
      bottom: -20px;
      right: 75px;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        right: 10px;
        width: 100%;
        height: 100%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bg {
      top: -50px;
      right: -50px;
      @media only screen and (max-width: 1300px) {
        right: 0;
      }
    }
  }
}

// About values

.about-values {
  padding: 120px 0;
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
  }
  .bg {
    top: 0;
    margin-left: 50px;
    @media only screen and (max-width: $r-768) {
      top: -75px;
    }
  }
  .text {
    margin-bottom: 80px;
    text-align: center;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 30px;
    }
  }
  .wrapper {
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        margin-bottom: 22px;
      }
    }
    .item {
      height: 100%;
      text-align: center;
      background-color: $blue-light-1;
      border: 2px solid $blue-light-2;
      border-radius: 10px;
      padding: 42px 40px;
      @media only screen and (max-width: $r-992) {
        padding: 34px 30px 30px 30px;
      }
      .icon {
        margin-bottom: 29px;
        img {
          @media only screen and (max-width: $r-768) {
            height: 70px;
          }
        }
      }
      .paragraph-extraBold-s {
        margin-bottom: 17px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 23px;
          p {
            font-size: 20px;
          }
        }
      }
      .paragraph-s {
        p {
          text-align: left;
          @media only screen and (max-width: $r-768) {
            font-size: 16px;
          }
        }
      }
    }
  }
}