// Modal contacts

#modal-contacts {
  background-color: rgba(0, 0, 0, 0.65);
  .modal-dialog {
    max-width: 1000px;
    .modal-content {
      border: 0;
      background: #FFFFFF;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      @media only screen and (max-width: $r-768) {
        border-radius: 20px;
      }
      .modal-body {
        position: relative;
        padding: 0;
        .btn-close {
          position: absolute;
          top: -25px;
          right: -25px;
          cursor: pointer;
          z-index: 2;
          transition: .3s;
          @media only screen and (max-width: $r-768) {
            top: 6px;
            right: 6px;
          }
          &:hover {
            transition: .3s;
            transform: rotate(180deg);
          }
          svg {
            width: 60px;
            height: 60px;
            &:first-of-type {
              @media only screen and (max-width: $r-768) {
                display: none;
              }
            }
            &:nth-of-type(2) {
              display: none;
              @media only screen and (max-width: $r-768) {
                display: block;
              }
            }
          }
        }
        .flex {
          display: flex;
          align-items: center;
          @media only screen and (max-width: $r-768) {
            flex-direction: column;
          }
          .text {
            width: 50%;
            padding: 30px 15px;
            text-align: center;
            @media only screen and (max-width: $r-768) {
              width: 100%;
              padding: 22px 15px 40px 15px;
            }
            .image {
              display: flex;
              justify-content: center;
              width: 100%;
              max-width: 308px;
              margin: 0 auto 48px auto;
              position: relative;
              z-index: 1;
              @media only screen and (max-width: $r-768) {
                max-width: 116px;
                margin-bottom: 21px;
              }
              img {
                width: 100%;
                max-width: 100%;
              }
              .bg {
                right: 0;
                bottom: 30px;
                @media only screen and (max-width: $r-768) {
                  right: -10px;
                  max-width: 168px;
                  svg {
                    width: 168px;
                    height: 124px;
                  }
                }
              }
              .vector {
                position: absolute;
                left: -20px;
                bottom: 0;
                z-index: -1;
                @media only screen and (max-width: $r-768) {
                  left: -10px;
                  bottom: -5px;
                  max-width: 104px;
                  height: 100%;
                  svg {
                    max-width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            .heading-h2 {
              margin-bottom: 28px;
              @media only screen and (max-width: $r-768) {
                font-size: 20px;
                line-height: 119.4%;
              }
            }
            .link-underline {
              @media only screen and (max-width: $r-768) {
                font-size: 12px;
              }
            }
          }
          .form {
            width: 50%;
            background-color: $blue-dark;
            padding: 70px;
            @media only screen and (max-width: $r-992) {
             padding: 70px 15px;
            }
            @media only screen and (max-width: $r-768) {
              width: 100%;
              padding: 40px 15px 15px 15px;
            }
            .heading-h2 {
              margin-bottom: 23px;
              text-align: center;
              color: white;
              @media only screen and (max-width: $r-768) {
                margin-bottom: 16px;
                font-size: 28px;
                line-height: 119.4%;
              }
            }
            .paragraph-l {
              margin-bottom: 41px;
              @media only screen and (max-width: $r-768) {
                margin-bottom: 32px;
              }
              p {
                text-align: center;
                color: white;
              }
            }
            form {
              width: 100%;
              .nf-before-form-content {
                display: none;
              }
              .nf-form-content {
                padding: 0;
                nf-fields-wrap {
                  nf-field {
                    .nf-field-container {
                      margin-bottom: 24px;
                      @media only screen and (max-width: $r-768) {
                        margin-bottom: 16px;
                      }
                      .nf-before-field {
                        display: none;
                      }
                      .nf-field-label {
                        display: none;
                      }
                      .nf-field-element {
                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                  .nf-after-field {
                    .nf-error-msg {
                      font-family: 'Raleway', sans-serif;
                      font-size: 14px;
                      color: red;
                    }
                  }
                }
              }
              input, textarea {
                width: 100%;
                height: 60px;
                outline: none;
                appearance: none;
                resize: none;
                padding: 0 27px;
                border: 0;
                background-color: white;
                border-radius: 50px;
                font-family: 'Raleway', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 149.4%;
                color: $blue-dark;
                @media only screen and (max-width: $r-768) {
                  height: 50px;
                  padding: 0 22px;
                  font-size: 14px;
                }
                &::placeholder {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 149.4%;
                  color: rgba(31, 37, 69, 0.5);
                  @media only screen and (max-width: $r-768) {
                    font-size: 14px;
                  }
                }
              }
              textarea {
                height: 111px;
                border-radius: 20px;
                padding: 17px 27px;
                @media only screen and (max-width: $r-768) {
                  padding: 13px 22px;
                }
              }
              input[type="submit"] {
                min-width: auto;
                background-color: $yellow;
                font-family: 'Raleway', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 127.4%;
                text-align: center;
                letter-spacing: 0.095em;
                text-transform: uppercase;
                color: black;
                @media only screen and (max-width: $r-768) {
                  font-size: 12px;
                }
                &:hover {
                  background-color: #FCD059;
                }
              }
            }
          }
        }
        .show-success {
          display: none;
        }
        .flex-success {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 72px 80px 72px 95px;
          @media only screen and (max-width: $r-768) {
            flex-direction: column;
            padding: 55px 27px;
          }
          .text {
            width: 100%;
            max-width: 275px;
            margin-right: 70px;
            @media only screen and (max-width: $r-768) {
              width: 100%;
              max-width: 100%;
              margin-right: 0;
              text-align: center;
              order: 2;
            }
            .heading-h2 {
              margin-bottom: 11px;
              @media only screen and (max-width: $r-768) {
                font-size: 28px;
              }
            }
            .paragraph-l {
              p {
                font-size: 20px;
              }
            }
          }
          .image {
            width: 100%;
            @media only screen and (max-width: $r-768) {
              text-align: center;
              margin-bottom: 48px;
              order: 1;
            }
            img {
              width: 100%;
              @media only screen and (max-width: $r-768) {
                max-width: 200px;
              }
            }
          }
        }
      }
    }
  }
}