// Global

body {
  font-family: 'PT Serif', serif;
  .container-fluid {
    max-width: 1440px;
  }
  .container {
    max-width: 1150px;
  }
  &.shadow {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 11;
    }
  }
}

p,
ul li,
ol li {
  position: relative;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 156.9%;
  color: $blue-dark;
  @media only screen and (max-width: $r-768) {
    font-weight: 500;
    font-size: 16px;
  }
  a {
    padding-bottom: 3px;
    position: relative;
    color: $blue-dark;
    &:hover {
      transition: .3s;
      color: $blue-dark;
      text-decoration: none;
      &:before {
        left: calc(50% - 5.5px);
        width: 11px;
      }
    }
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 0;
      background-color: $yellow;
      border-radius: 17px;
      transition: .3s;
    }
  }
}

.paragraph-extraBold-l {
  p {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 127.4%;
    color: $blue-dark;
    @media only screen and (max-width: $r-768) {
      font-size: 20px;
    }
  }
}

.paragraph-extraBold-s {
  p {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 127.4%;
    color: $blue-dark;
  }
}

.paragraph-semiBold {
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 141.9%;
    color: $blue-dark;
    @media only screen and (max-width: $r-768) {
      font-size: 20px;
    }
  }
}

.paragraph-semiMedium {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 141.9%;
    color: $blue-dark;
  }
}

.paragraph-l {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 156.9%;
    color: $blue-dark;
    @media only screen and (max-width: $r-768) {
      font-weight: 500;
      font-size: 16px;
      line-height: 141.9%;
    }
  }
}

.paragraph-s {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 149.4%;
    color: $blue-dark;
    @media only screen and (max-width: $r-768) {
      font-size: 12px;
      line-height: 127.4%;
    }
  }
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

i {
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 149.4%;
  color: $blue-dark;
  @media only screen and (max-width: $r-768) {
    font-size: 16px;
  }
}

.link {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 127.4%;
  text-align: center;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: black;
}

.link-underline {
  position: relative;
  padding-bottom: 3px;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 127.4%;
  text-align: center;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: black;
  &:hover {
    transition: .3s;
    text-decoration: none;
    color: black;
    &:before {
      left: calc(50% - 5.5px);
      width: 11px;
    }
  }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: $yellow;
    border-radius: 17px;
    transition: .3s;
  }
}

.additional-l {
  p {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 127.4%;
    color: $blue-dark;
    @media only screen and (max-width: $r-768) {
      font-weight: 500;
      font-size: 16px;
    }
  }
  &.uppercase {
    p {
      text-transform: uppercase;
      @media only screen and (max-width: $r-768) {
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}

.additional-s {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 127.4%;
    color: $blue-dark;
  }
}

.heading-h1 {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 62px;
  letter-spacing: -1.8px;
  color: $blue-dark;
  @media only screen and (max-width: $r-768) {
    font-size: 28px;
    line-height: 33px;
    padding-left: 2px;
  }
  span {
    display: inline-flex;
    position: relative;
    &.line {
      &:before {
        width: 100%;
        transition: .3s;
      }
    }
    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 4px;
      left: 0;
      bottom: -4px;
      background-color: $marine;
      border-radius: 17px;
      transition: .3s;
      @media only screen and (max-width: $r-768) {
        height: 2px;
      }
    }
  }
}

.heading-h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 61px;
  letter-spacing: -1.8px;
  color: $blue-dark;
}

.heading-h3 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 127.4%;
  color: $blue-dark;
}

.button-yellow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 392px;
  height: 60px;
  background-color: $yellow;
  border-radius: 153px;
  transition: .3s;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 127.4%;
  text-align: center;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: black;
  @media only screen and (max-width: $r-768) {
    min-width: 100%;
    height: 50px;
    font-size: 12px;
  }
  &:hover {
    transition: .3s;
    background-color: #FCD059;
    color: black;
    text-decoration: none;
  }
}

.button-transparent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 392px;
  height: 60px;
  background-color: white;
  border: 2px solid $yellow;
  border-radius: 153px;
  transition: .3s;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 127.4%;
  text-align: center;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: black;
  @media only screen and (max-width: $r-768) {
    min-width: 100%;
    height: 50px;
    font-size: 12px;
  }
  &:hover {
    transition: .3s;
    border-color: #FCD059;
    background-color: #FCD059;
    color: black;
    text-decoration: none;
  }
}

nav.navigation {
  display: inline-flex;
  ul {
    display: flex;
    li {
      margin: 0 30px;
      &.current-menu-item {
        a {
          &:before {
            left: 0;
            width: 100%;
            max-width: 100%;
          }
        }
      }
      a {
        min-height: 50px;
        position: relative;
        padding-bottom: 3px;
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 127.4%;
        text-align: center;
        letter-spacing: 0.095em;
        text-transform: uppercase;
        color: black;
        &:hover {
          transition: .3s;
          text-decoration: none;
          color: black;
          &:before {
            left: calc(50% - 16px);
            width: 100%;
            max-width: 35px;
          }
        }
        &:before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          height: 2px;
          width: 0;
          max-width: 35px;
          background-color: $yellow;
          border-radius: 17px;
          transition: .3s;
        }
        //&:after {
        //  position: absolute;
        //  content: '';
        //  background-color: $yellow;
        //  left: -24px;
        //  top: -18px;
        //  height: 50px;
        //  width: 0;
        //  border-radius: 90px;
        //  transition: .3s;
        //  z-index: -1;
        //}
        &.active {
          &:before {
            left: 0;
            width: 100%;
            max-width: 100%;
          }
          //&:after {
          //  width: calc(100% + 48px);
          //  transition: .3s;
          //}
        }
      }
    }
  }
}

.downloads {
  ul {
    display: flex;
    li {
      margin-left: 15px;
      &:last-of-type {
        svg {
          position: relative;
          left: 3px;
        }
      }
      a {
        display: flex;
        position: relative;
        &:before {
          display: none;
        }
        &:hover {
          transition: .3s;
          .icon {
            background-color: #FCD059;
          }
          .tooltip-item {
            transition: .3s;
            opacity: 1;
            z-index: 1;
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background-color: $yellow;
          border-radius: 50%;
          transition: .3s;
          @media only screen and (max-width: $r-768) {
            width: 70px;
            height: 70px;
            svg {
              height: 34px;
              width: 34px;
            }
          }
        }
        .tooltip-item {
          opacity: 0;
          z-index: -1;
          transition: .3s;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          bottom: -45px;
          background-color: white;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.13);
          border-radius: 30px;
          min-width: 175px;
          height: 35px;
          padding: 10px;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 127.4%;
          text-align: center;
          letter-spacing: 0.095em;
          text-transform: uppercase;
          color: #000000;
        }
      }
    }
  }
}

.socials {
  ul {
    display: flex;
    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 23px;
      &:first-child {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
        &:before {
          display: none;
        }
      }
      &:before {
        position: absolute;
        content: '';
        right: 0;
        width: 1px;
        height: 35px;
        background-color: #D9D9D9;
        opacity: 0.3;
      }
      a {
        &:hover {
          svg {
            path {
              transition: .3s;
              fill: #FCD059;
            }
          }
        }
        &:before {
          display: none;
        }
        svg {
          path {
            transition: .3s;
          }
        }
      }
    }
  }
}

.bg {
  position: absolute;
  svg {
    overflow: initial;
    &.show-desctop {
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768) {
        display: block;
      }
    }
  }
}

.slider {
  .swiper-pagination {
    bottom: 0!important;
    .swiper-pagination-bullet {
      outline: none;
      width: 6px;
      height: 6px;
      margin: 0 5px;
      background-color: $blue;
      opacity: 0.2;
      transition: .3s;
      &.swiper-pagination-bullet-active {
        opacity: 1;
        transition: .3s;
      }
    }
  }
}

.arrows {
  .swiper-button-next,
  .swiper-button-prev {
    position: initial;
    outline: none;
    width: 60px;
    height: 60px;
    margin-left: 20px;
    &:after {
      display: none;
    }
    &:hover {
      transition: .3s;
      svg {
        circle {
          fill: $blue;
        }
        path {
          stroke: white;
        }
      }
    }
    svg {
      circle,
      path {
        transition: .3s;
      }
    }
  }
}

body {
  #wpadminbar {
    ul, ol {
      li {
        a {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

// SVG animation

.bg {
  svg {
    overflow: initial;
    path {
      &:nth-child(even) {
        animation: movedelement 12s infinite linear;
      }
      &:nth-child(odd) {
        animation: movedelement2 9s infinite linear;
      }
    }
    circle {
      position: relative;
      animation: opacity 10s linear infinite;
    }
  }
}

@keyframes movedelement {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, 10px);
  }
  50% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(10px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes movedelement2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  75% {
    transform: translate(-5px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes rotatedelement {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    transform: translate(15px, -5px) rotate(15deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes rotatedelement2 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(5px, 5px) rotate(4deg);
  }
  50% {
    transform: translate(10px, 10px) rotate(5deg);
  }
  75% {
    transform: translate(20px, 20px) rotate(6deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes opacity {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

// Elementor

body .elementor-widget:not(:last-child) {
  margin-bottom: 0;
}