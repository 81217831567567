.page-id-10 {
  .home-download {
    background-color: $blue-light-1;
  }
}

// Faq hero

.faq-hero {
  padding: 150px 0 90px 0;
  background-color: $blue-light-1;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 115px 0 60px 0;
  }
  .bg {
    @media only screen and (max-width: $r-768) {
      right: 0;
      bottom: 18px;
      z-index: 2!important;
    }
  }
  .row {
    align-items: center;
  }
  .text {
    @media only screen and (max-width: $r-768) {
      margin-bottom: 75px;
      text-align: center;
    }
    .heading-h1 {
      margin-bottom: 23px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 36px;
      }
    }
    .question {
      margin-bottom: 40px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 35px;
        p {
          font-size: 20px;
          b {
            font-weight: 600;
          }
        }
      }
    }
    .answer {
      width: 100%;
      max-width: 351px;
      border: 4px solid $blue;
      border-radius: 28px;
      padding: 30px 44px;
      position: relative;
      @media only screen and (max-width: $r-768) {
        max-width: 275px;
        border-width: 2px;
        padding: 20px 20px 20px 28px;
        margin: 0 auto;
      }
      .icon {
        position: absolute;
        left: -4px;
        top: -17px;
        background-color: $blue-light-1;
        padding: 0 21px 14px 0;
        @media only screen and (max-width: $r-768) {
          left: -2px;
          top: -10px;
          svg {
            width: 33px;
            height: 21px;
          }
        }
      }
      .paragraph-l {
        p {
          margin-bottom: 0;
          @media only screen and (max-width: $r-768) {
            text-align: left;
            font-size: 18px;
          }
        }
      }
    }
  }
  .image {
    position: relative;
    text-align: right;
    z-index: 1;
    img {
      width: 100%;
      max-width: 475px;
      position: relative;
      z-index: 1;
    }
    .vector {
      max-width: 100%;
      position: absolute;
      right: 80px;
      bottom: 0;
      z-index: -1;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .bg {
      right: 47px;
      bottom: -55px;
      z-index: 1;
      @media only screen and (max-width: $r-992) {
        max-width: 100%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// Faq list

.faq-list {
  padding: 95px 0 130px 0;
  overflow: hidden;
  @media only screen and (max-width: $r-768) {
    padding: 30px 0;
  }
  div[class*="col-"] {
    position: relative;
    @media only screen and (max-width: $r-768) {
      position: initial;
    }
  }
  .select-mob {
    display: none;
    @media only screen and (max-width: $r-768) {
      display: block;
      margin-bottom: 45px;
      min-height: 50px;
    }
    &.fixed {
      .wrapper {
        width: 100%;
        min-width: auto;
        max-width: calc(100% - 30px);
        position: fixed;
        top: 87px;
        left: 15px;
        @media only screen and (max-width: $r-768) {
          &:after {
            position: absolute;
            content: '';
            background-color: white;
            left: -15px;
            top: -15px;
            height: calc(100% + 30px);
            width: calc(100% + 30px);
            z-index: -1;
          }
        }
      }
    }
    .button-transparent {
      justify-content: flex-start;
      text-align: left;
      padding: 0 26px;
      background-image: url("../img/icon-faq-select.svg");
      background-position: 95%;
      background-size: 24px;
      background-repeat: no-repeat;
    }
  }
  .sidebar {
    position: sticky;
    top: 130px;
    @media only screen and (max-width: $r-768) {
      position: fixed;
      bottom: -100vh;
      top: initial;
      left: 0;
      background-color: white;
      width: 100%;
      border-radius: 40px 40px 0 0;
      padding: 48px 48px 15px 48px;
      z-index: -1;
      transition: .3s;
      &.active {
        bottom: 0;
        z-index: 12;
        transition: .3s;
      }
    }
    .icon-close {
      display: none;
      position: absolute;
      right: 24px;
      top: 24px;
      @media only screen and (max-width: $r-768) {
        display: block;
      }
    }
    .additional-l {
      margin-bottom: 22px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 35px;
      }
      p {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .navigation {
      margin-bottom: 60px;
      @media only screen and (max-width: $r-768) {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      ul {
        flex-direction: column;
        align-items: flex-start;
        li {
          margin: 0;
          a {
            display: flex;
            min-height: 50px;
            font-weight: 500;
            font-size: 20px;
            line-height: 235.9%;
            text-transform: none;
            @media only screen and (max-width: $r-992) {
              font-size: 16px;
              svg {
                width: 100%;
                height: 100%;
              }
            }
            @media only screen and (max-width: $r-992) {
              font-size: 14px;
            }
            &:before {
              bottom: 10px;
            }
            &:after {
             top: initial;
              @media only screen and (max-width: $r-992) {
                top: -8px;
              }
            }
          }
        }
      }
    }
  }
  .item {
    .item-section {
      margin-bottom: 90px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 60px;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      margin: 0 0 22px 0;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      .icon {
        width: 80px;
        min-width: 80px;
        margin-right: 23px;
        @media only screen and (max-width: $r-768) {
          width: 50px;
          min-width: 50px;
          margin-right: 16px;
        }
      }
      .heading-h2 {
        @media only screen and (max-width: $r-768) {
          font-size: 28px;
        }
      }
    }
    .accordion {
      .accordion-item {
        margin-bottom: 16px;
        border: 2px solid #EDEFFB;
        padding: 0;
        background-color: $blue-light-1;
        border-radius: 10px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 4px;
        }
        &.active {
          background-color: white;
          .heading-h3 {
            font-weight: 800;
          }
        }
        .heading-h3 {
          margin-bottom: 0;
          padding: 35px 80px 35px 50px;
          width: 100%;
          font-weight: 500;
          background-image: url("../img/icon-accordion-minus.svg");
          background-position: 100% 19px;
          background-repeat: no-repeat;
          background-size: 60px;
          cursor: pointer;
          @media only screen and (max-width: $r-768) {
            padding: 25px 55px 25px 30px;
            background-size: 50px;
            font-size: 16px;
            line-height: 130%;
            background-image: url("../img/icon-accordion-minus-mob.svg");
          }
          &.collapsed {
            background-image: url("../img/icon-accordion-plus.svg");
            @media only screen and (max-width: $r-768) {
              background-image: url("../img/icon-accordion-plus-mob.svg");
            }
          }
        }
        .accordion-collapse {
          .accordion-body {
            padding: 0 50px 35px 50px;
            @media only screen and (max-width: $r-768) {
              padding: 0 55px 25px 30px;
            }
            ol {
              list-style: decimal;
              padding-left: 20px;
              li {
                margin-bottom: 10px;
              }
              ol {
                padding-left: 40px;
              }
            }

            ul {
              list-style: disc;
              padding-left: 20px;
              li {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}