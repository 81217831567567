// Footer

.footer {
  padding: 62px 0 43px 0;
  background-color: $blue-dark;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 30px 0
  }
  .row {
    justify-content: space-between;
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          width: calc(100% - 30px);
          height: 1px;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  .logo {
    margin-bottom: 26px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 30px;
      text-align: center;
    }
    img {
      height: 52px;
    }
  }
  .additional-l {
    width: 100%;
    max-width: 236px;
    @media only screen and (max-width: $r-768) {
      margin: 0 auto;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      color: white;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 0;
        text-align: center;
        opacity: .5;
      }
    }
  }
  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: $r-768) {
      width: 100%;
    }
    .navigation {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: $r-768) {
        justify-content: center;
        .menu {
          margin-bottom: 21px;
        }
      }
    }
  }
  nav {
    width: 100%;
    max-width: 140px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%;
    }
    ul {
      flex-direction: column;
      li {
        margin: 0 0 21px 0;
        line-height: 17px;
        @media only screen and (max-width: $r-768) {
          text-align: center;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        &.current_page_item {
          a {
            &:before {
              width: 0;
              max-width: 35px;
            }
            &:hover {
              &:before {
                width: 100%;
              }
            }
          }
        }
        a {
          color: white;
          &:hover {
            color: white;
          }
        }
      }
    }
  }
  .additional-s {
    width: 100%;
    max-width: 140px;
    margin-bottom: 11px;
    @media only screen and (max-width: $r-768) {
      text-align: center;
      max-width: 100%;
      margin-bottom: 22px;
    }
    p {
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      color: white;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 0;
        opacity: .5;
      }
    }
  }
  .downloads {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      width: 100%;
      max-width: 140px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 0;
        max-width: 100%;
      }
      li {
        margin: 0 15px 0 0;
        @media only screen and (max-width: $r-768) {
          margin: 0 19px;
        }
      }
    }
  }
  .flex-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media only screen and (max-width: $r-768) {
      align-items: center;
      width: 100%;
    }
    .additional-s {
      max-width: 200px;
    }
  }
  .socials {
    width: 100%;
    max-width: 205px;
    margin-bottom: 20px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%;
      padding-bottom: 30px;
      margin-bottom: 30px;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    ul {
      @media only screen and (max-width: $r-768) {
        justify-content: space-between;
        max-width: 250px;
        margin: 0 auto;
      }
      li {
        @media only screen and (max-width: $r-768) {
          padding: 0;
        }
        &:before {
          @media only screen and (max-width: $r-768) {
            display: none;
          }
        }
        a {
          svg {
            @media only screen and (max-width: $r-768) {
              width: 34px;
              height: 34px;
            }
          }
        }
      }
    }
  }
  .locations {
    width: 100%;
    max-width: 205px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%;
    }
    .item {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      @media only screen and (max-width: $r-768) {
        justify-content: center;
      }
      .icon {
        width: 24px;
        min-width: 24px;
        margin-right: 10px;
        @media only screen and (max-width: $r-768) {
          opacity: 0.5;
        }
      }
      .additional-s {
        margin: 0;
        position: relative;
        top: 1px;
        @media only screen and (max-width: $r-768) {
          width: auto;
        }
        p, a {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 14px;
          text-transform: none;
        }
        a {
          word-break: break-all;
          color: white;
          &:hover {
            color: white;
            &:before {
              left: 0;
              width: 100%;
            }
          }
          &:before {
            left: 50%;
            width: 0;
          }
        }
      }
    }
  }
  .bottom {
    align-items: center;
    div[class="col-lg-12"] {
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        margin-bottom: 30px;
        padding-bottom: 0;
        &:before {
          display: none;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .line {
      margin: 35px 0;
      width: 100%;
      height: 1px;
      background-color: #D9D9D9;
      opacity: 0.2;
    }
    .avocado {
      transition: .3s;
      opacity: 0.5;
      &:hover {
        transition: .3s;
        opacity: 1;
      }
    }
  }
  .copyright {
    max-width: 100%;
    text-align: right;
    @media only screen and (max-width: $r-768) {
      text-align: center;
    }
    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      text-transform: none;
      @media only screen and (max-width: $r-768) {
        opacity: 1;
        color: white;
      }
    }
  }
}