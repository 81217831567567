// Home hero

.home-hero {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  background-color: $blue-light-1;
  padding: 145px 0 20px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 115px 0 30px 0;
  }
  .bg {
    top: 226px;
    margin-left: -20px;
    @media only screen and (max-width: $r-768) {
      top: 240px;
    }
    svg {
      @media only screen and (max-width: $r-992) {
        width: 100%;
      }
    }
  }
  .text {
    text-align: center;
    width: 100%;
    max-width: 892px;
    margin: 0 auto 105px auto;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 35px;
    }
    .heading-h1 {
      margin-bottom: 40px;
      line-height: 61px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 27px;
        line-height: 33px;
      }
      .vector {
        position: absolute;
        right: -25px;
        top: -35px;
        @media only screen and (max-width: $r-768) {
          right: 0;
          top: -18px;
        }
        svg {
          @media only screen and (max-width: $r-768) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    p {
      width: 100%;
      max-width: 831px;
      margin: 0 auto 35px auto;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 45px;
      }
    }
    .paragraph-semiBold {
      p {
        @media only screen and (max-width: $r-768) {
          width: 100%;
          max-width: 288px;
          margin: 0 auto 20px auto;
        }
      }
    }
    .additional-l {
      p {
        @media only screen and (max-width: $r-768) {
          margin-bottom: 0;
        }
      }
      a {
        @media only screen and (max-width: $r-768) {
          display: inline-block;
        }
      }
    }
    .form {
      width: 100%;
      margin-bottom: 21px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 30px;
      }
      form {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        @media only screen and (max-width: $r-768) {
          flex-direction: column;
        }
        input, .dropdown-toggle {
          border: 0;
          outline: none;
          appearance: none;
          font-family: 'Raleway', sans-serif;
        }
        .dropdown {
          width: 100%;
          @media only screen and (max-width: $r-768) {
            margin-bottom: 20px;
          }
          .filter-option {
            height: 100%;
            .filter-option-inner {
              height: 100%;
              .filter-option-inner-inner {
                height: 100%;
                display: flex;
                align-items: center;
                opacity: .5;
              }
            }
          }
        }
        input[type="text"], .dropdown-toggle {
          position: relative;
          z-index: 5;
          width: 100%;
          height: 60px;
          background-color: #FFFFFF;
          box-shadow: 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
          border: 1px solid transparent;
          border-radius: 1000px;
          padding: 0 180px 0 28px;
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 141.9%;
          color: black;
          @media only screen and (max-width: $r-768) {
            height: 50px;
            padding: 0 23px;
            font-size: 16px;
            z-index: 8;
          }
          &:after {
            display: none;
          }
          &:hover {
            box-shadow: 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
            border-color: #D6EAF2;
          }
          &::placeholder {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 141.9%;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        input[type="submit"] {
          position: absolute;
          z-index: 6;
          right: 0;
          background-color: $blue;
          border-radius: 100px;
          height: 60px;
          width: 151px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 127.4%;
          text-align: center;
          letter-spacing: 0.095em;
          text-transform: uppercase;
          color: #FFFFFF;
          transition: .3s;
          @media only screen and (max-width: $r-768) {
            position: initial;
            height: 50px;
            width: 100%;
          }
          &:hover {
            transition: .3s;
            background-color: rgba(37, 83, 240, 0.85);
          }
        }
        .dropdown-menu {
          margin: -58px 0 0 0;
          padding: 58px 0 0 0;
          background-color: white;
          box-shadow: 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
          border: 0;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
          transform: translate3d(0, 60px, 0px)!important;
          height: 300px;
          position: relative;
          z-index: 4;
          @media only screen and (max-width: $r-768) {
            z-index: 7;
            margin: 0;
            padding: 55px 0 0 0;
            transform: none!important;
            max-width: 100%;
            width: 100%;
          }
          .inner {
            transform: initial!important;
            height: auto;
            ul {
              padding-top: 0;
              li {
                a {
                  padding: 9px 30px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 141.9%;
                  color: black;
                  @media only screen and (max-width: $r-768) {
                   font-size: 16px;
                  }
                  &.active {
                    background-color: $yellow;
                  }
                  &:before {
                    display: none;
                  }
                  span {
                    margin: 0;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .slider {
    .swiper {
      overflow: initial;
      .swiper-wrapper {
        transition-timing-function : linear;
      }
      .swiper-slide {
        width: 219px;
        @media only screen and (max-width: $r-768) {
          width: 143px;
        }
        &.swiper-slide-active {
          .item {
            .info {
              .paragraph-s {
                transition: .3s;
                @media only screen and (max-width: $r-768) {
                  opacity: 1;
                }
                &:before {
                  @media only screen and (max-width: $r-768) {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
        .item {
          text-align: center;
          &:hover {
            transition: .3s;
            .info {
              .paragraph-s {
                &:before {
                  opacity: 1;
                }
              }
            }
          }
          .avatar {
            margin-bottom: 11px;
            width: 219px;
            height: 219px;
            position: relative;
            z-index: 1;
            @media only screen and (max-width: $r-768) {
              width: 143px;
              height: 143px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .info {
            .paragraph-s {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 60px;
              max-width: 181px;
              margin: 0 auto;
              @media only screen and (max-width: $r-768) {
                opacity: 0;
                transition: .3s;
              }
              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background-image: url("../img/vector-team-position.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                opacity: 0;
                transition: .3s;
              }
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

// Home how works

.home-how-works {
  display: flex;
  justify-content: center;
  padding: 120px 0 90px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
  }
  .bg {
    top: 78px;
    @media only screen and (max-width: $r-768) {
      top: 200px;
      svg {
        width: 100%;
      }
    }
  }
  .text {
    text-align: center;
    .additional-l {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
    }
    .heading-h1 {
      width: 100%;
      max-width: 670px;
      margin: 0 auto 53px auto;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
    }
    .paragraph-l {
      width: 100%;
      max-width: 786px;
      margin: 0 auto 55px auto;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 46px;
      }
    }
    .paragraph-extraBold-l {
      margin-bottom: 48px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 17px;
      }
    }
  }
  .wrapper {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    position: relative;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 0;
    }
    div[class*="col-"] {
      padding-left: 0;
      padding-right: 0;
      &:last-of-type {
        .item {
          @media only screen and (max-width: $r-992) {
            margin-bottom: 30px;
          }
        }
      }
    }
    .item {
      text-align: center;
      @media only screen and (max-width: $r-992) {
        margin-bottom: 70px;
      }
      .icon {
        margin-bottom: 25px;
        height: 70px;
        img {
          height: 100%;
        }
      }
      .paragraph-extraBold-l {
        margin-bottom: 18px;
        p {
          @media only screen and (max-width: $r-992) {
            font-size: 28px;
          }
        }
      }
      .additional-l {
        width: 100%;
        max-width: 287px;
        margin: 0 auto 40px auto;
        p {
          font-weight: 500;
        }
      }
      .image {
        text-align: center;
        img {
          height: 370px;
          @media only screen and (max-width: $r-768) {
            height: 262px;
            width: auto;
          }
        }
      }
    }
  }
  .text-bottom {
    text-align: center;
    .button-transparent {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 17px;
      }
    }
    .paragraph-s {
      p {
        font-size: 14px;
      }
    }
  }
}

// Home customers

.home-customers {
  background-color: $blue-light-1;
  padding: 120px 0 90px 0;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
  }
  .bg.vector-mobile-top {
    display: none;
    right: 20px;
    top: -50px;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: block;
    }
  }
  .bg.vector-mobile-bottom {
    display: none;
    right: 100px;
    bottom: 140px;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: block;
    }
  }
  .text {
    @media only screen and (max-width: $r-992) {
      text-align: center;
    }
    .additional-l {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .heading-h1 {
      margin-bottom: 42px;
    }
    .paragraph-l {
      width: 100%;
      max-width: 530px;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto;
      }
      &:last-of-type {
        margin-bottom: 80px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 46px;
        }
      }
    }
  }
  .list {
    width: 100%;
    max-width: 480px;
    @media only screen and (max-width: $r-992) {
      max-width: 100%;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        @media only screen and (max-width: $r-992) {
          margin-bottom: 35px;
        }
        .icon {
          width: 64px;
          min-width: 64px;
          margin-right: 28px;
          @media only screen and (max-width: $r-768) {
            width: 70px;
            min-width: 70px;
            margin-right: 25px;
          }
        }
      }
    }
  }
  .image {
    margin: 75px 150px 0 0;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      margin-right: 0;
      margin-bottom: 38px;
      justify-content: center;
    }
    img {
      width: 100%;
      max-width: 295px;
      @media only screen and (max-width: $r-768) {
        max-width: 260px;
      }
    }
    .bg {
      right: 190px;
      top: -75px;
      @media only screen and (max-width: $r-768) {
        right: 5px;
        top: -70px;
      }
    }
    .vector {
      position: absolute;
      right: 44px;
      top: -30px;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        right: 85px;
        top: -30px;
        svg {
          width: 204px;
          height: 226px;
        }
      }
    }
  }
  .review {
    position: relative;
    min-height: 455px;
    margin-top: -75px;
    z-index: 1;
    @media only screen and (max-width: $r-768) {
      min-height: auto;
      margin-top: 0;
    }
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-image: url("../img/vecot-review.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
    .item {
      width: 100%;
      max-width: 370px;
      padding: 75px 0 0 40px;
      @media only screen and (max-width: $r-768) {
        display: flex;
        flex-direction: column;
        padding: 0;
      }
      .icon {
        margin-bottom: 20px;
        height: 33px;
        @media only screen and (max-width: $r-768) {
          order: 1;
          margin: 0 0px -18px 37px;
          height: 34px;
          z-index: 1;
          svg {
            height: 100%;
          }
        }
      }
      .additional-l {
        margin-bottom: 8px;
        @media only screen and (max-width: $r-768) {
          order: 3;
          margin-bottom: 6px;
          padding-left: 32px;
        }
        p {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 20px;
          @media only screen and (max-width: $r-768) {
            font-size: 14px;
          }
        }
      }
      .additional-s {
        margin-bottom: 27px;
        @media only screen and (max-width: $r-768) {
          order: 4;
          margin-bottom: 20px;
          padding-left: 32px;
        }
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 14px;
          @media only screen and (max-width: $r-768) {
            font-size: 10px;
          }
        }
      }
      i {
        @media only screen and (max-width: $r-768) {
          order: 2;
          position: relative;
          padding: 32px;
          margin-bottom: 36px;
          background-color: #FFFFFF;
          border: 2px solid $blue-light-2;
          border-radius: 25px;
          font-size: 14px;
          &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-bottom: solid 16px transparent;
            border-top: solid 16px $blue-light-2;
            border-left: solid 16px transparent;
            border-right: solid 16px transparent;
            bottom: -33px;
            left: 21px;
          }
          &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-bottom: solid 15px transparent;
            border-top: solid 15px #fff;
            border-left: solid 16px transparent;
            border-right: solid 15px transparent;
            bottom: -29px;
            left: 21px;
          }
        }
      }
    }
  }
}

// Home providers

.home-providers {
  padding: 120px 0 90px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
  }
  .bg.vector-mobile-bottom {
    display: none;
    right: 100px;
    bottom: 140px;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: block;
    }
  }
  .text {
    @media only screen and (max-width: $r-992) {
      text-align: center;
    }
    .additional-l {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .heading-h1 {
      margin-bottom: 42px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
    }
    .paragraph-l {
      width: 100%;
      max-width: 530px;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto;
      }
      &:last-of-type {
        margin-bottom: 80px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 46px;
        }
      }
    }
  }
  .row {
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-992) {
          order: 2;
        }
      }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-992) {
          order: 1;
          margin-bottom: 55px;
        }
      }
    }
  }
  .list {
    width: 100%;
    max-width: 480px;
    margin-bottom: 66px;
    @media only screen and (max-width: $r-992) {
      max-width: 100%;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 35px;
        }
        .icon {
          width: 64px;
          min-width: 64px;
          margin-right: 28px;
          @media only screen and (max-width: $r-768) {
            width: 70px;
            min-width: 70px;
            margin-right: 25px;
          }
        }
      }
    }
  }
  .button-yellow {
    margin-left: 92px;
    min-width: 260px;
    @media only screen and (max-width: $r-768) {
      margin-left: 0;
      min-width: 100%;
    }
  }
  .image {
    max-width: 300px;
    margin: 11px 0 0 100px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-992) {
      margin-left: 0;
      margin-bottom: 38px;
      justify-content: center;
    }
    @media only screen and (max-width: $r-768) {
      margin: 0 auto 38px auto;
      max-width: 260px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
    .bg {
      right: 0;
      top: -30px;
      @media only screen and (max-width: $r-768) {
        right: 30px;
        top: -90px;
        svg {
          width: 100%;
        }
      }
    }
    .vector {
      position: absolute;
      left: 25px;
      bottom: -40px;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        top: -30px;
        left: 50px;
        bottom: initial;
        svg {
          width: 204px;
          height: 226px;
        }
      }
    }
  }
  .review {
    display: flex;
    justify-content: flex-end;
    position: relative;
    min-height: 455px;
    margin: -24px 50px 0 0;
    z-index: 1;
    @media only screen and (max-width: $r-768) {
      justify-content: flex-start;
      min-height: auto;
      margin: 0;
    }
    &:before {
      position: absolute;
      content: '';
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url("../img/vector-review-2.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
    .item {
      width: 100%;
      max-width: 370px;
      padding: 75px 40px 0 0;
      text-align: right;
      @media only screen and (max-width: $r-768) {
        display: flex;
        flex-direction: column;
        padding: 0;
        text-align: left;
      }
      .icon {
        margin-bottom: 20px;
        height: 33px;
        @media only screen and (max-width: $r-768) {
          order: 1;
          margin: 0 0px -18px 37px;
          height: 34px;
          z-index: 1;
          svg {
            height: 100%;
          }
        }
      }
      .additional-l {
        margin-bottom: 8px;
        @media only screen and (max-width: $r-768) {
          order: 3;
          margin-bottom: 6px;
          padding-left: 32px;
        }
        p {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 20px;
          @media only screen and (max-width: $r-768) {
            font-size: 14px;
          }
        }
      }
      .additional-s {
        margin-bottom: 27px;
        @media only screen and (max-width: $r-768) {
          order: 4;
          margin-bottom: 20px;
          padding-left: 32px;
        }
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 14px;
          @media only screen and (max-width: $r-768) {
            font-size: 10px;
          }
        }
      }
      i {
        @media only screen and (max-width: $r-768) {
          order: 2;
          position: relative;
          padding: 32px;
          margin-bottom: 36px;
          background-color: #FFFFFF;
          border: 2px solid $blue-light-2;
          border-radius: 25px;
          font-size: 14px;
          &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-bottom: solid 16px transparent;
            border-top: solid 16px $blue-light-2;
            border-left: solid 16px transparent;
            border-right: solid 16px transparent;
            bottom: -33px;
            left: 21px;
          }
          &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-bottom: solid 15px transparent;
            border-top: solid 15px #fff;
            border-left: solid 16px transparent;
            border-right: solid 15px transparent;
            bottom: -29px;
            left: 21px;
          }
        }
      }
    }
  }
}

// Home about

.home-about {
  padding: 90px 0;
  background-color: $blue-light-1;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
  }
  .row {
    align-items: center;
  }
  .text {
    @media only screen and (max-width: $r-992) {
      text-align: center;
    }
    .additional-l {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .heading-h1 {
      margin-bottom: 42px;
    }
    .paragraph-l {
      p {
        &:last-of-type {
          margin-bottom: 50px;
          @media only screen and (max-width: $r-768) {
            margin-bottom: 40px;
          }
        }
      }
    }
    .button-yellow {
      min-width: 260px;
      @media only screen and (max-width: $r-768) {
        min-width: 100%;
      }
    }
  }
  .image {
    &.show-desctop {
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
    &.show-mobile {
      display: none;
      @media only screen and (max-width: $r-768) {
        display: block;
      }
    }
  }
}

// Home download

.home-download {
  padding: 120px 0;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
    position: relative;
  }
  .bg {
    @media only screen and (max-width: $r-768) {
      top: -40px;
      right: 20px;
      svg {
        width: 100%;
      }
    }
  }
  .image {
    position: relative;
    @media only screen and (max-width: $r-992) {
      text-align: center;
    }
    .bg {
      position: absolute;
      left: -80px;
      top: -40px;
      @media only screen and (max-width: $r-992) {
        display: none;
      }
    }
    img {
      width: 100%;
      max-width: 595px;
    }
  }
  .row {
    div[class*="col-"] {
      &:first-of-type {
        @media only screen and (max-width: $r-992) {
          order: 2;
        }
      }
      &:nth-of-type(2) {
        @media only screen and (max-width: $r-992) {
          order: 1;
        }
      }
    }
  }
  .text {
    @media only screen and (max-width: $r-992) {
      text-align: center;
      margin-bottom: 40px;
    }
    .additional-l {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .heading-h1 {
      width: 100%;
      max-width: 290px;
      margin-bottom: 42px;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto 42px auto;
      }
      @media only screen and (max-width: $r-992) {
        margin: 0 auto 28px auto;
      }
    }
    .paragraph-l {
      width: 100%;
      max-width: 403px;
      @media only screen and (max-width: $r-992) {
        margin: 0 auto 16px auto;
      }
      @media only screen and (max-width: $r-992) {
        margin: 0 auto 36px auto;
      }
      p {
        &:last-of-type {
          margin-bottom: 32px;
        }
      }
    }
    .downloads {
      ul {
        @media only screen and (max-width: $r-992) {
          justify-content: center;
        }
        li {
          margin: 0 15px 0 0;
        }
      }
    }
  }
}

// Home testimonials

.home-testimonials {
  display: flex;
  justify-content: center;
  background-color: $blue-light-1;
  padding: 120px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 40px 0;
    position: relative;
  }
  .bg {
    top: 12px;
    @media only screen and (max-width: $r-768) {
      top: -26px;
    }
    svg {
      max-width: 100%;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 40px;
      justify-content: center;
      text-align: center;
    }
    .additional-l {
      margin-bottom: 20px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 16px;
      }
    }
    .arrows {
      display: flex;
      position: relative;
      @media only screen and (max-width: $r-768) {
        display: none;
      }
    }
  }
  .slider {
    .swiper {
      padding-bottom: 35px;
    }
    .swiper-slide {
      .item {
        background-color: white;
        border: 3px solid $blue-light-2;
        border-radius: 10px;
        padding: 40px;
        @media only screen and (max-width: $r-768) {
          border-radius: 25px;
          padding: 33px 40px;
        }
        &:hover {
          transition: .3s;
          .link-underline {
            opacity: 1;
          }
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 25px;
          .avatar {
            width: 44px;
            min-width: 44px;
            margin-right: 21px;
            img {
              width: 100%;
              border-radius: 50%;
            }
          }
          .info {
            @media only screen and (max-width: $r-768) {
              text-align: left;
            }
            .additional-l {
              margin-bottom: 5px;
              p {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 16px;
              }
            }
            .additional-s {
              p {
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }
        .paragraph-s {
          @media only screen and (max-width: $r-768) {
            margin-bottom: 35px;
          }
          p {
            display: none;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            &:first-of-type {
              display: -webkit-box;
            }
            @media only screen and (max-width: $r-768) {
              font-size: 14px;
              line-height: 141.9%;
            }
          }
        }
        .link-underline {
          opacity: 0;
          transition: .3s;
          @media only screen and (max-width: $r-768) {
            opacity: 1;
          }
        }
      }
    }
  }
  .modal {
    background-color: rgba(0, 0, 0, 0.65);
    .modal-dialog {
      max-width: 662px;
      .modal-content {
        position: relative;
        background-color: white;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
        border: 0;
        border-radius: 30px;
        @media only screen and (max-width: $r-768) {
          border-radius: 20px;
        }
        .close {
          opacity: 1;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $yellow;
          width: 74px;
          height: 74px;
          right: -37px;
          top: -37px;
          transition: .3s;
          z-index: 1;
          cursor: pointer;
          @media only screen and (max-width: $r-768) {
            width: 32px;
            height: 32px;
            top: 6px;
            right: 6px;
            background-color: transparent;
            border-radius: 0;
          }
          &:hover {
            transition: .3s;
            transform: rotate(180deg);
          }
          .show-desctop {
            @media only screen and (max-width: $r-768) {
              display: none;
            }
          }
          .show-mobile {
            display: none;
            @media only screen and (max-width: $r-768) {
              display: block;
            }
          }
        }
        .modal-body {
          padding: 0;
          .item {
            padding: 60px;
            @media only screen and (max-width: $r-768) {
              padding: 28px 22px;
            }
            .flex {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 25px;
              .avatar {
                width: 44px;
                min-width: 44px;
                margin-right: 21px;
                img {
                  width: 100%;
                  border-radius: 50%;
                }
              }
              .info {
                @media only screen and (max-width: $r-768) {
                  text-align: left;
                }
                .additional-l {
                  margin-bottom: 5px;
                  p {
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 16px;
                  }
                }
                .additional-s {
                  p {
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 14px;
                  }
                }
              }
            }
            .paragraph-s {
              p {
                font-weight: 400;
                font-size: 20px;
                line-height: 156.9%;
                &:last-of-type {
                  margin-bottom: 0;
                }
                @media only screen and (max-width: $r-768) {
                  font-size: 14px;
                  line-height: 141.9%;
                }
              }
            }
          }
        }
      }
    }
  }
}